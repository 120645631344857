import React from "react";
import "./App.css";
import logo from "./images/logo-full.svg";

function App() {
  return (
    <div className="bg">
      <div className="bg-overlay"></div>

      <img
        src={logo}
        alt="Herman & Co"
        className="h-[80px] md:h-[110px] absolute top-7 transform translate-x-1/2 right-1/2"
      />

      <div className="transform translate-x-1/2 -translate-y-1/2 absolute right-1/2 top-1/2 flex flex-col items-center   md:pt-[160px]">
        <h1 className="text-white relative text-[40px] font-bold tracking-[5px] uppercase text-center">
          Мужская парикмахерская НА Чистых прудах
        </h1>
        <a
          target="_blank"
          href="https://b11133.yclients.com/company/30187/menu?o="
          rel="noreferrer noopener"
          className="button "
        >
          Записаться
        </a>
      </div>
      <p className="text-white absolute bottom-5 transform translate-x-1/2 right-1/2">
        Чистопрудный бульвар 12 к 4, Москва
        <br />
        м. Чистые пруды
        <br />
        м. Тургеневская
        <br />
        м. Сретенский б-р
        <br />
        Пн — Сб с 10:00 по 22:00
        <br />
        +7 965 101 3335
      </p>
    </div>
  );
}

export default App;
